/* global styles */

/* html overrides */
html,
body {
  @apply m-0 h-full w-full bg-white;
}

html.dark,
body.dark {
  @apply bg-gray-zero;
}

#__next {
  height: 100%;
}

/* scrollbar */
html {
  @apply overflow-y-auto overflow-x-hidden;
}

/* selection */
* ::selection {
  @apply bg-gray-four text-gray-zero;
}

.dark * ::selection {
  @apply bg-gray-one text-white;
}

/* marker effect */
.has-marker {
  position: relative;
  white-space: nowrap;
  z-index: 1;

  &::after {
    border-radius: 18px 0 18px 0;
    background-image: linear-gradient(
      170deg,
      rgba(154, 221, 255, 0.1) 0%,
      rgba(154, 221, 255, 0.5) 29%,
      rgba(154, 221, 255, 0.1) 100%
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 12px;
    content: '';
    height: 20px;
    left: -6px;
    position: absolute;
    right: -6px;
    z-index: -1;
  }
}

/* marker effect dark mode */
.dark {
  .has-marker {
    &::after {
      background-image: linear-gradient(
        170deg,
        rgba(169, 61, 115, 0.5) 0%,
        rgba(169, 61, 115, 0.9) 29%,
        rgba(169, 61, 115, 0.5) 100%
      );
    }
  }
}
