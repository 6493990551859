/* nprogress.module.css */

/* Make clicks pass-through */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  /* skeleton */
  @apply fixed top-0 left-0 h-1 w-full;

  /* color */
  @apply bg-gray-one;

  /* custom */
  z-index: 1031;
}

.dark #nprogress .bar {
  @apply bg-gray-five;
}

/* Fancy blur effect */
#nprogress .peg {
  /* skeleton */
  @apply absolute right-0 block h-full w-24 opacity-100;

  /* color */
  @apply shadow-default;

  /* custom */
  transform: rotate(3deg) translate(0px, -4px);
}

.dark #nprogress .peg {
  @apply shadow-default-dark;
}

.nprogress-custom-parent {
  @apply relative overflow-hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  @apply absolute;
}
