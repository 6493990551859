/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  /* NOTE: this is to overwrite z-index of Toastify,
  in ToastProvider it doesn not work */
  @apply !text-gray-one !z-[100000000000];
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  @apply !font-default !shadow-hover !mx-auto !mt-4 !min-h-0 !max-w-[calc(100vw-16px)] !rounded-2xl !font-normal !leading-normal !tracking-wide md:!mt-0;
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  padding: 0.5rem 0.65rem !important;
  width: 100% !important;

  > div {
    width: 100% !important;
  }
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  @apply !bg-gray-zero !text-white;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  @apply !bg-gray-zero !text-white;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply !bg-success !text-gray-five;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  @apply !bg-warning !text-gray-one;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply !bg-error !text-gray-five;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/* Custom toast */
.Toastify__undo {
  @apply !bg-gray-zero !text-white;

  width: 340px !important;

  .Toastify__toast-body {
    @apply !font-default !min-h-0 !rounded-2xl !p-0 !font-normal !leading-normal !tracking-wide;
  }
}

/* dark theme */
:global(.dark) .Toastify__toast-container {
  @apply !text-gray-five;
}

:global(.dark) .Toastify__toast {
  @apply !shadow-hover-dark;
}

:global(.dark) .Toastify__toast--error {
  @apply !bg-error-dark;
}

:global(.dark) .Toastify__toast--info {
  @apply !bg-gray-five !text-black;
}

:global(.dark) .Toastify__undo {
  @apply !bg-gray-five !text-black;
}
