/* page styles */

.button-container {
  /* skeleton */
  @apply flex w-full flex-wrap justify-center;

  /* alignment */
  &.button-left {
    justify-content: flex-start;

    a,
    button {
      @apply mb-2 first:-ml-2.5 md:first:-ml-3;
    }
  }

  &.button-right {
    justify-content: flex-end;

    a,
    button {
      @apply mb-2 last:-mr-3;
    }
  }
}

.blog-tags-container {
  @apply flex w-full flex-wrap justify-start;
}

.excerpt-container {
  div {
    font-size: 22px;
    line-height: 1.5;
  }
}

.privacy-container {
  /* skeleton */
  @apply mt-4 flex w-full content-center items-center justify-center;

  /* custom */
  svg {
    @apply mr-1 mb-2 h-4 w-4 text-black;
  }
}

.dark .privacy-container {
  svg {
    @apply text-gray-five;
  }
}
