/* cookie consent */

.ch2-container {
  font-family: Source Sans Pro, sans-serif !important;
}

.ch2-dialog {
  border-radius: 12px !important;
}

.ch2-dialog-actions button {
  height: auto !important;
}

.ch2-style-dark .ch2-dialog {
  background-color: #5b5a5a !important;
}

.ch2-theme-default .ch2-dialog p:last-child {
  margin-top: 6px !important;
  margin-bottom: 16px !important;
}

.ch2-style-dark .ch2-dialog-content a {
  font-size: 15px !important;
}

.ch2-theme-default .ch2-dialog-actions button {
  width: auto !important;
}

.ch2-btn {
  align-items: center !important;
  border-radius: 12px !important;
  border-style: solid !important;
  border-width: 3px !important;
  box-shadow: none !important;
  cursor: pointer !important;
  display: inline-flex !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  justify-content: center !important;
  letter-spacing: 1px !important;
  margin: 0 1rem 0 0 !important;
  outline: 0 !important;
  padding: 0.6rem 1rem 0.7rem !important;
  text-decoration: none !important;
  text-transform: inherit !important;
  transition: all 200ms ease !important;
}

.ch2-settings-content {
  .ch2-btn {
    margin-bottom: 0.5rem !important;
  }
}

.ch2-style-light .ch2-btn-primary,
.ch2-style-dark .ch2-btn-primary,
.ch2-allow-all-btn.ch2-btn-primary {
  background-color: #2f2f2e !important;
  border-color: #2f2f2e !important;
  color: #ffffff !important;

  &:hover {
    background-color: #151515 !important;
    border-color: #151515 !important;
    box-shadow: 0 1px 3px rgba(91, 90, 90, 0.25) !important;
    color: #ffffff !important;
  }
}

.ch2-open-settings-btn.ch2-btn-secondary {
  background-color: transparent !important;
  border-bottom: 2px solid transparent !important;
  border-color: transparent !important;
  border-left: none !important;
  border-radius: 0 !important;
  border-right: none !important;
  border-top: none !important;
  box-shadow: none !important;
  color: #f5f4f3 !important;
  padding: 0.2rem !important;

  &:hover {
    background-color: transparent !important;
    border-color: #f5f4f3 !important;
    box-shadow: none !important;
    color: #f5f4f3 !important;
  }

  @media screen and (max-width: 450px) {
    padding: 0.6rem 1rem 0.7rem !important;
  }
}

.ch2-btn-secondary {
  background-color: #2f2f2e !important;
  border-color: #2f2f2e !important;
  color: #ffffff !important;

  &:hover {
    background-color: #151515 !important;
    border-color: #151515 !important;
    box-shadow: 0 1px 3px rgba(91, 90, 90, 0.25) !important;
    color: #ffffff !important;
  }
}

.ch2-style-dark .ch2-settings {
  background-color: #5b5a5a !important;
  border-radius: 12px !important;
}

.ch2-style-dark .ch2-settings-actions {
  background-color: #5b5a5a !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.ch2-settings-actions {
  border-top: 1px solid #f5f4f3 !important;
}

.ch2-style-light .ch2-switch input:checked + label,
.ch2-style-dark .ch2-switch input:checked + label {
  background-color: #2f2f2e !important;
}

.ch2-settings-tab-container > p {
  margin-bottom: 12px !important;

  &:last-of-type {
    margin-bottom: 18px !important;
  }
}

.ch2-style-dark .ch2-settings-scan .ch2-settings-tabs ul {
  border-bottom: none !important;

  @media screen and (max-width: 450px) {
    padding: 0 35px !important;
  }
}

.ch2-settings-tabs {
  border-bottom: 1px solid #f5f4f3 !important;
  box-shadow: none !important;
  padding: 0 0 0.5rem 0 !important;
}

.ch2-style-dark .ch2-settings-header {
  box-shadow: none !important;
}

.ch2-style-dark .ch2-settings-scan .ch2-settings-tabs ul li.active a {
  align-items: center !important;
  background-color: #2f2f2e !important;
  border-bottom-color: #2f2f2e !important;
  border-radius: 12px !important;
  border-style: solid !important;
  border-width: 3px !important;
  border: none !important;
  box-shadow: none !important;
  color: #ffffff !important;
  cursor: pointer !important;
  display: inline-flex !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: 0.7px !important;
  margin-bottom: 0.5rem !important;
  margin: 0 1rem 0 0 !important;
  outline: 0 !important;
  padding: 0.6rem 1rem 0.7rem !important;
  text-decoration: none !important;
  text-transform: inherit !important;
  transition: all 200ms ease !important;

  @media screen and (max-width: 450px) {
    font-size: 13px !important;
    padding: 0.4rem 0.6rem !important;
    margin: 0 0.5rem 0 0 !important;
  }
}

.ch2-style-dark .ch2-settings-scan .ch2-settings-tabs ul li a {
  align-items: center !important;
  background-color: transparent !important;
  border-bottom-color: transparent !important;
  border-radius: 12px !important;
  border-style: solid !important;
  border-width: 3px !important;
  border: none !important;
  box-shadow: none !important;
  color: #f5f4f3 !important;
  cursor: pointer !important;
  display: inline-flex !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: 0.7px !important;
  margin-bottom: 0.5rem !important;
  margin: 0 1rem 0 0 !important;
  outline: 0 !important;
  padding: 0.6rem 1rem 0.7rem !important;
  text-decoration: none !important;
  text-transform: inherit !important;
  transition: all 200ms ease !important;

  @media screen and (max-width: 450px) {
    font-size: 13px !important;
    padding: 0.4rem 0.6rem !important;
    margin: 0 0.5rem 0 0 !important;
  }
}

.ch2-settings-tab-container {
  .ch2-btn {
    font-size: 15px !important;
    padding: 0.5rem 0.8rem 0.6rem !important;
  }
}
