/* import global styles */
@import './tailwind.css';
@import './globals.css';

/* import package styles */
@import './cookie-consent.css';
@import './iframe.css';
@import './nprogress.css';
@import './toastify.css';

/* import page styles */
@import './pages.css';
