/* iframe optin */

.iframe-wrapper {
  @apply flex h-full w-full content-center items-center justify-center rounded-2xl;
}

.optin-canvas {
  @apply flex h-full w-full flex-col content-center items-center justify-center rounded-2xl p-4;

  .info-text {
    /* skeleton */
    @apply mb-8 w-full max-w-prose text-center font-default leading-normal tracking-wide;

    /* size */
    @apply text-xl lg:text-xl;

    /* color */
    @apply text-gray-one;

    a {
      /* skeleton */
      @apply cursor-pointer select-none border-t-0 border-l-0 border-r-0 border-b border-solid font-default font-normal leading-normal tracking-normal no-underline outline-none transition-all ease-linear focus:outline-none;

      /* color */
      @apply border-black bg-transparent text-black hover:border-primary hover:text-primary;
    }
  }

  .icon-wrapper {
    @apply mb-4 flex h-24 w-full justify-center;

    svg {
      @apply h-24 w-24 fill-current text-gray-one;
    }
  }

  .optin-button {
    /* skeleton */
    @apply mt-0 mr-1 mb-0 ml-0 flex cursor-pointer select-none content-center items-center justify-center whitespace-nowrap border-solid no-underline outline-none transition-all ease-linear focus:outline-none disabled:cursor-not-allowed;

    /* font */
    @apply font-buttons font-semibold leading-none tracking-wide;

    /* size */
    @apply h-10 rounded-2xl border-2 py-1.5 px-2.5 text-sm leading-none focus:ring-2 md:text-base;

    /* color */
    @apply border-black bg-black text-white hover:border-gray-one hover:bg-gray-one hover:text-white focus:ring-gray-one  focus:ring-opacity-50 disabled:hover:border-black  disabled:hover:bg-black  disabled:hover:text-white;
  }
}

:global(.dark) .optin-canvas {
  .info-text {
    /* color */
    @apply text-gray-four;

    a {
      /* color */
      @apply border-white text-white hover:border-primary-dark hover:text-primary-dark;
    }
  }

  .icon-wrapper {
    svg {
      @apply text-gray-four;
    }
  }

  .optin-button {
    /* color */
    @apply border-gray-four bg-gray-four text-black hover:border-gray-three hover:bg-gray-three hover:text-gray-zero focus:ring-gray-three disabled:hover:border-gray-four disabled:hover:bg-gray-four disabled:hover:text-gray-one;
  }
}
